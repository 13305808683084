<template>
  <b-modal
    id="modal-faq"
    ref="refModal"
    title="Câu hỏi tiền khám bệnh"
    ok-title="Lưu"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    scrollable
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <b-row>

          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="title"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Nội dung câu hỏi <span class="text-danger">(*)</span>
                </template>
                <b-form-textarea
                  id="title"
                  v-model="itemLocal.title"
                  placeholder="Nội dung câu hỏi"
                  rows="2"
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>

              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <template v-slot:label>
                Thứ tự hiển thị <span class="text-danger">(*)</span>
              </template>
              <spin-button
                v-model="itemLocal.order"
                :min="1"
                :step="1"
                :default-value="itemLocal.order"
              />
            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            class="mt-2"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="itemLocal.status"
                name="check-button"
                switch
                inline
              >
                Hiển thị?
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- submit -->
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormCheckbox,
  VBTooltip,
  BFormTextarea,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SpinButton from '@/views/components/SpinButton.vue';
import { togglePasswordConfirmVisibility } from '@/layouts/mixins/forms';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import useFaqModal from './useFaqModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BForm,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    SpinButton,
    BFormTextarea,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility, togglePasswordConfirmVisibility],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = useFaqModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
