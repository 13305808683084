import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFaqs(ctx, data) {
      return useJwt.getFaqs(data).then(response => response);
    },
    createFaq(ctx, data) {
      return useJwt.createFaq(data).then(response => response);
    },
    updateFaq(ctx, data) {
      return useJwt.updateFaq(data).then(response => response);
    },
    deleteFaq(ctx, id) {
      return useJwt.deleteFaq(id).then(response => response);
    },
  },
};
